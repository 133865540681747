import { Layout, Spin } from "antd";
import RightMenu from "layouts/RightMenu";
import SubHeader from "layouts/SubHeader/SubHeader";
import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { paths } from "../constants";
import { featureKeys, routeApp } from "../constants/keys";
import { doCheckUser } from "../ducks/slices/authSlice";
import { rid } from "../helpers";
import { useAuth, useEventAppointment, useIndexedDb } from "../hooks";
import i18n, { languageKeys } from "../i18n";
import { NotFound } from "../pages";
import EventSourceClient from "../routes/eventSource/EventSourceClient";
import { FeatureRoute } from "./route";

const KetQua = React.lazy(() => import("../pages/PhQuanLyBenhNhan/KetQua/KetQua"));
const HoSoKhamBenh = React.lazy(() => import("../pages/PhQuanLyBenhNhan/HoSoKhamBenh/HoSoKhamBenh"));
const QuanLyKhachHang = React.lazy(() => import("../pages/PhQuanLyBenhNhan/QuanLyKhachHang/QuanLyKhachHang"));


const BenhNhanMenuItems = [
  {
    featureKey: `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.ket_qua}`,
    key: "/benh-nhan/ket-qua",
    label: "Quản lý kết quả",
  },
  {
    featureKey:  `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.benh_an}`,
    key: "/benh-nhan/benh-an",
    label: "Quản lý hồ sơ bệnh án",
  },
  {
    featureKey:  `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.khach_hang}`,
    key: "/benh-nhan/khach-hang",
    label: "Quản lý khách hàng",
  },
];

export const BenhNhanRoute = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const { user, tokens } = useSelector((state) => state.auth);
  const { loadingInitData } = useIndexedDb();
  const { eventClient, reloadAppointment } = useEventAppointment();

  const currentRoute = window.location.href;
  useEffect(() => {
    if (!user && !!tokens) {
      dispatch(doCheckUser({...tokens,logout}));
    }
  }, [dispatch, tokens?.token]);

  if (!user) {
    return <LoadingComponent />;
  }
  return (
    <Spin
      spinning={loadingInitData}
      tip={
        <div style={{ textAlign: "center" }}>
          <div>{i18n.t(languageKeys.text_khoi_tao_du_lieu)}</div>
        </div>
      }
    >
      <App redirectPath={PathPhBenhNhan.ketQua} currentRoute={currentRoute} />

      <EventSourceClient ref={eventClient} callEventHandlers={{ reloadAppointment }} />
    </Spin>
  );
};

const App = memo(({ redirectPath, currentRoute }) => {
  const baoCaoRisRef = useRef();
  const history = useHistory();

  const handleNavigate = ({ keyPath, key }) => {
    history.push(keyPath.pop());
    if (baoCaoRisRef.current) {
      baoCaoRisRef?.current.setViewKey(key);
    }
  };

  console.log(`featureRoutes`, featureRoutes);
  return (
    <Layout>
      <SubHeader menuItem={BenhNhanMenuItems} onNavigate={handleNavigate} module={routeApp.BENH_NHAN}/>
      <Layout>
        <Layout.Content>
          <Switch>
            {featureRoutes.map((route) => (
              <FeatureRoute key={rid()} exact path={route.path} component={route.component} featureKey={route.key} />
            ))}
            <Redirect exact from={paths.benhNhan} to={redirectPath} />
            <Route component={NotFound} />
          </Switch>
        </Layout.Content>
        <RightMenu currentFeature={routeApp.QL_BENH_NHAN} />
      </Layout>
    </Layout>
  );
});

const LoadingComponent = () => (
  <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Spin spinning />
    <h3 className="blue-txt">{i18n.t(languageKeys.updatingAccInfo)}...</h3>
  </div>
);

export const PathPhBenhNhan = {
  ketQua: "/benh-nhan/ket-qua",
  benhAn: "/benh-nhan/benh-an",
  khachHang: "/benh-nhan/khach-hang",
};

const featureRoutes = [
  {
    key: `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.ket_qua}`,
    path: PathPhBenhNhan.ketQua,
    component: KetQua,
  },
  {
    key:  `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.benh_an}`,
    path: PathPhBenhNhan.benhAn,
    component: HoSoKhamBenh,
  },
  {
    key:  `${featureKeys.phan_he_quan_ly_benh_nhan}.${featureKeys.khach_hang}`,
    path: PathPhBenhNhan.khachHang,
    component: QuanLyKhachHang,
  },
];
